import React from 'react'
import {
  Breadcrumbs,
  Hero,
  Page,
  Products,
} from '@components'

const meta = {
  title: 'Protupožarno brtvljenje - Proizvodi | Protupožarna zaštita',
  description: 'Protupožarno brtvljenje ima važnu ulogu u svakom projektu protupožarne zaštite jer u slučaju požara spriječava širenje vatre i dima izvan granica požarnih sektora te omogućuje vrijeme potrebno za evakuaciju ljudi i pokretne imovine.',
  image: require('@assets/images/protupozarno-brtvljenje.jpg'),
}

const Homepage = () => (
  <Page
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/proizvodi/', name: 'Proizvodi'},
        { link: `/proizvodi/protupozarno-brtvljenje/`, name: 'Protupožarno brtvljenje '},
      ]}
    />
    <Hero
      description="Protupožarno brtvljenje ima važnu ulogu u svakom projektu protupožarne zaštite jer u slučaju požara spriječava širenje vatre i dima izvan granica požarnih sektora te omogućuje vrijeme potrebno za evakuaciju ljudi i pokretne imovine."
      slim
      subtitle="Protupožarno brtvljenje"
      title="Proizvodi"
    />
    <Products activeCategory="protupozarno-brtvljenje" />
  </Page>
)

export default Homepage